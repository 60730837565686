<template>
  <div class="offer-card">
    <div
      class="offer-image"
      :style="{ 'background-image' : `url( ${ media } )` }"
    >
      <div class="unlocked-bg"></div>
      <span class="text-white">Offer</span>
    </div>
    <div class="offer-capture">
      <h3>{{ title }}</h3>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    },
    media: {
      type: String,
      required: true
    },
  }
}
</script>

<style lang="scss" scoped>
.offer-card {
  overflow: hidden;
  border-radius: 3px;

  .offer-image {
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 150px;
    position: relative;
    @media (max-width: 575px) {
      height: 200px;
    }
    @media (min-width: 768px) and (max-width: 991px) {
      height: 200px;
    }
    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      margin-right: -50%;
      transform: translate(-50%, -50%);
      z-index: 2;
    }
    span {
      position: absolute;
      bottom: 10px;
      font-size: 12px;
      display: block;
      padding: 5px 15px;
      background-color: $primary-grey-color;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }
  }
  .offer-capture {
    height: 115px;
    background: $white-color;
    position: relative;
    h3 {
      margin-top: 0;
      margin-bottom: 15px;
      padding-top: 12.5px;
      padding-left: 12.5px;
      padding-right: 12.5px;
      color: $primary-grey-color;
    }
    p {
      margin-top: 0;
      margin-bottom: 0;
      padding-left: 12.5px;
      padding-right: 12.5px;
    }
  }
}
</style>
